import React, {useState} from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../scss/style.scss'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()

  const [navigate,setNavigate] = useState(false);
  const changeBackground = () => {  
    if(window.scrollY >= 80) {
      setNavigate(true)
    }else {
      setNavigate(false)
    }
  }
  if (typeof window !== `undefined`) {
    window.addEventListener('scroll', changeBackground);
  }

  return (
    <>
    <div id="scrollTo"></div>
    <div>
      <script src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit" async defer />
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/og-image.jpg`}
        />
        
      </Helmet>
      <div className={navigate ? 'navigate active' : 'navigate'}>
        <Navbar />
      </div>
      <div id="mainish">{children}</div>
      <Footer />
    </div>
    </>
  )
}

export default TemplateWrapper
