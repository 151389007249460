import React, { Component } from 'react'

export class arrow extends Component {
    render() {
        return (
            
                <svg xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 772.2 763" >
                
                <g>
                    <path d="M386.6,0c26.5,1.1,75.3,63.3,93,81c71,71.3,142,142.7,213,214c13.2,13.2,75.8,68.6,79,82
                        c3.8,16-10.5,23.5-17,30c-21,21-42,42-63,63c-73.3,73.3-146.7,146.7-220,220c-16.9,16.9-59.3,72.6-86,73
                        c-63.2-30.4,7.7-77.7,33-103c83-83,166-166,249-249c-171.3,0-342.7,0-514,0C154,410.9,0,416.9,0,381.9c0-38,153.4-30.1,153-30
                        c168,0,346.6,0.1,514.6,0.1c-102.3-102.3-204.7-204.7-307-307C353,21.2,370.3,8.7,386.6,0z"/>
                </g>
                </svg>

        )
    }
}

export default arrow
